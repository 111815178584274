<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <!-- <h3 class="mb-0">Contas a receber</h3></div> -->
              <p>
                <b>Produto: </b
                >{{ lista_finan_contas_receber.pedido.nome_produto }}
              </p>
        

              <p>
                <b>Valor Pago: </b>
                {{
                  lista_finan_contas_receber.pedido.valor_total | formatMoneyPYBR
                }}
              </p>
            </div>
            <div class="col-md-3">
              <button @click="NovaParcela()" class="btn btn-info">
                Nova Parcela
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          {{ lista_campos.nome_produto }}
          {{ lista_campos.data_vencimento }}
        </div>

        <div class="card-body">
          <b-table
            :fields="[
              'id',
              'forma_pagamento',
              'vencimento',
              'emissao',
              'valor',
              'cambio',
              'status',
              'acoes',
            ]"
            :items="lista_finan_contas_receber.contas_receber"
            :per-page="perPage"
            :current-page="currentPage"
            id="contas_receber-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>

           
<!-- 
            <template #cell(vencimento)="{ item }">
              {{ item.vencimento | formatDBDateToLocale }}
            </template> -->
            <template #cell(valor)="{ item }">
              {{ item.valor | formatMoneyPYBR }}
            </template>
            <template #cell(status)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.status === 2,
                  'label-light-danger': data.item.status === 1,
                  'label-light-warning': data.item.status === 3,
                }"
              >
                {{
                  data.item.status === 2
                    ? "Pago"
                    : data.item.status === 1
                    ? "Aberto"
                    : "Parcial"
                }}
              </span>
            </template>
            <template #cell(acoes)="{ item }">
              <button
                v-if="item.baixa === null"
                v-show="lista_permissoes_filial.lock_FinanCambio"
                @click="abrirModalFinan(item)"
                class="btn btn-icon btn-light btn-sm mx-1 text-warning"
                v-b-tooltip.hover
                title="Dar Baixa"
              >
                <i class="far fa-list-alt text-warning"></i>
              </button>
              <!-- <button
                v-if="item.baixa !== null"
                @click="verComprovante(item)"
                class="btn btn-icon btn-light btn-sm mx-1 disabled"
              >
                <i class="far fa-list-alt text-info"></i>
              </button> -->
              <!-- <button
                v-if="item.status !== 2"
                @click="OpenPagamento(item)"
                class="btn btn-icon btn-light btn-sm mx-1 disabled"
              >
                <i class="far fa-file text-primary"></i>
              </button> -->
              <button
                v-if="item.baixa === null"
                v-show="lista_permissoes_filial.u_FinanPedidoRecebimento"
                @click="ShowModalEditarConta(item)"
                class="btn btn-icon btn-light btn-sm mx-1"
                v-b-tooltip.hover
                title="Editar registro"
              >
                <i class="far fa-edit text-primary"></i>
              </button>
              <span v-b-tooltip="`Recebido ${item.baixa}`">
                <button
                  v-if="item.baixa !== null"
                  v-show="lista_permissoes_filial.lock_FinanCambio"
                  class="btn btn-icon btn-light btn-sm mx-1 disabled"
                >
                  <i class="far fa-list-alt text-success"></i>
                </button>
              </span>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_finan_contas_receber.contas_receber.length"
            :per-page="perPage"
            aria-controls="contas_receber-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modal_parcela.show"
      title="Financeiro"
      centered
      hide-footer
    >
      <b-overlay :show="modal_parcela.loading" rounded="sm">
        <form>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="contrato">Valor: G$</label>
              <input
                id="valor"
                type="text"
                class="form-control"
                v-model="modal_parcela.form.valor"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12">
              <label for="parcelas">Moeda:*</label>
              <select
                id="parcelas"
                class="form-control"
                v-model="modal_parcela.form.moeda_id"
              >
                <option
                  v-for="item in lista_finan_moedas"
                  :key="item"
                  :value="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12 text-right">
              <button @click.prevent="submitPedido()" class="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>

    <b-modal
      v-model="modal_finan.show"
      title="Recebimento do Titulo"
      centered
      hide-footer
    >
      <b-overlay :show="modal_finan.loading" rounded="sm">
        <form>
          <div class="form-group row">
            <div class="col-md-12">
              <label>Data Pagamento</label>
              <input
                class="form-control"
                type="date"
                v-model="modal_finan.form.baixa"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="parcelas">forma Pagamento:*</label>
              <select
                id="parcelas"
                class="form-control"
                v-model="modal_finan.form.finan_forma_pagamento_id"
              >
                <option
                  v-for="item in lista_finan_forma_pagamentos"
                  :key="item"
                  :value="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        
          <!-- <div class="form-group row">
            <div class="col-md-12">
              <label for="contrato">Descricao: </label>
              <input
                  id="valor"
                 type="text"
                  class="form-control"
              
                v-model="modal_parcela.form.descricao"
                  
              />
            </div>
          </div> -->

          <div class="form-group row">
            <div class="col-md-12 text-right">
              <button @click.prevent="confirm()" class="btn btn-primary">
                Dar Baixa
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>

    <b-modal
      v-model="finan_contas.show"
      title="Recebimento do Titulo"
      centered
      hide-footer
    >
      <b-overlay :show="finan_contas.loading" rounded="sm">
        <form>
          <div class="form-group row">
            <div class="col-md-12">
              <label>Vencimento</label>
              <input
                class="form-control"
                type="date"
                v-model="finan_contas.form.vencimento"
              />
            </div>
          </div>
          <div
            v-if="finan_contas.form.finan_forma_pagamento_id > 1"
            class="form-group row"
          >
            <div class="col-md-12">
              <label for="parcelas">Forma Pagamento:*</label>
              <select
                id="parcelas"
                class="form-control"
                v-model="finan_contas.form.finan_forma_pagamento_id"
              >
                <option
                  v-for="item in lista_finan_forma_pagamentos"
                  :key="item"
                  :value="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
    
          <div class="form-group row">
            <div class="col-md-12">
              <label for="contrato">Valor:*</label>
              <input
                id="contrato"
                type="text"
                class="form-control"
                v-model="finan_contas.form.valor"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <label class="col-md-12">Status :*</label>
              <select class="form-control" v-model="finan_contas.form.status">
                <option value="2">Pago</option>
                <option value="1">Aberto</option>
                <option value="0">Excluir</option>
                <option value="3">Pendente</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12 text-right">
              <button @click.prevent="update()" class="btn btn-primary">
                Atualiazar
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import moneyFormat from "@/core/mixins/moneyFormat";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins: [fireAlert, moneyFormat, dateFormat],
  data() {
    return {
      finan_contas: {
        show: false,
        loading: false,
        form: {
          finan_forma_pagamento_id: "",
          vencimento: "",
          valor: "",
          status: "",
          id: "",
          finan_conta_id: "",
        },
      },
      currentPage: 1,
      perPage: 10,
      modal_parcela: {
        show: false,
        loading: false,

        form: {
          valor: null,
          moeda_id: null,
          perdido_id: null,
        },
      },
      modal_finan: {
        show: false,
        loading: false,

        form: {
          finan_forma_pagamento_id: null,
          descricao: null,
          id_finan_conta_recebimento: null,
          finan_conta_id: null,
          baixa: null,
          finan_pedido_id: null,
        },
      },
      valorParcela: null,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contas a Receber" }]);
  },
  computed: {
    lista_finan_contas() {
      return this.$store.state.financeiro.lista_finan_contas;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_finan_contas_receber() {
      return this.$store.state.financeiro.lista_finan_contas_receber;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_finan_moedas() {
      return this.$store.state.financeiro.lista_finan_moedas;
    },
    lista_finan_forma_pagamentos() {
      return this.$store.state.financeiro.lista_finan_forma_pagamentos;
    },
      link_boleto(){
      return this.$store.state.financeiro.link_boleto
    },
  },
  created() {
    this.listar_finan_contas_receber();
  },
  methods: {
    async update() {
      this.finan_contas.loading = true;

      this.verif = true;

      await this.$store.dispatch(
        "update_finan_contas_receber",
        this.finan_contas.form
      );
      this.verif = false;
      this.fireAlert(this.mensagem_alert);
      this.finan_contas.loading = false;
      this.finan_contas.show = false;
      this.listar_finan_contas_receber();
    },
    // async listar_contas() {
    //   await this.$store.dispatch("financeiro/listar_finan_contas").finally(() => {
    //     //     this.$store.dispatch("configEmpresa/atualizar", "");
    //   });
    // },
    async abrirModalFinan(value) {
      this.modal_finan.show = true;
      this.modal_finan.form.id_finan_conta_recebimento = value.id;
      this.listar_finan_forma_pagamento();
      // this.listar_contas();
    },
    async listar_finan_forma_pagamento() {
        await this.$store.dispatch("financeiro/listar_finan_forma_pagamento").finally(() => {
            // this.$store.dispatch("atualizar", "");
      });
    },

    async submitPedido() {
        await this.$store
        .dispatch("financeiro/criarParcela", this.modal_parcela.form)
        .finally(() => {
              });
      this.listar_finan_contas_receber();

      this.modal_parcela.show = false;
    },
    NovaParcela() {
      let { pedido_id } = this.$route.query;
      this.modal_parcela.form.perdido_id = pedido_id;
      this.modal_parcela.show = true;
    },


    async excluir(item) {
  
      await this.$store.dispatch("financeiro/lock_parcelas", item).finally(() => {
    
        this.listar_finan_contas_receber();
      });
      this.fireAlert(this.mensagem_alert);
    },
    async listar_finan_contas_receber() {
        let { pedido_id } = this.$route.query;
      await this.$store
        .dispatch("financeiro/listar_finan_contas_recebers_by_pedido_id", pedido_id)
        .finally(() => {
                this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    async confirm() {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação dara baixa nessa conta a receber?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.baixar_finan_contas_receber();
          }
        },
      });
    },

    async verComprovante(item) {
      // window.open('http://example.com', '_blank');
      await this.$store.dispatch("financeiro/ver_comprovante_entrada_conta_receber", item);

      this.$router.push({ name: "comprovante" });
      //  let routeData = this.$router.resolve({name: 'comprovante'});
      // window.open(routeData.href, '_blank');
    },
   async baixar_finan_contas_receber() {
      let { pedido_id } = this.$route.query;
      this.modal_finan.form.finan_pedido_id = pedido_id;
        await this.$store.dispatch(
        "financeiro/baixar_finan_contas_receber",
        this.modal_finan.form
      );
        this.fireAlert(this.mensagem_alert);
      this.modal_finan.show = false;
      this.listar_finan_contas_receber();
    },
    ShowModalEditarConta(value) {
      this.listar_finan_forma_pagamento();
      this.listar_contas();
      console.log(value);
      this.finan_contas.show = true;
      this.finan_contas.form.id = value.id;
      this.finan_contas.form.finan_conta_id = value.conta_id;
      this.finan_contas.form.valor = value.valor;
      this.finan_contas.form.vencimento = value.vencimento;
      this.finan_contas.form.finan_forma_pagamento_id =
        value.finan_forma_pagamento_id;
      this.finan_contas.form.status = value.status;
      this.finan_contas.form.finan_cambio_id = value.finan_cambio_id;
    },
    async OpenPagamento(item) {
         if (item.status == 1) {
   let aluno_id = parseInt(localStorage.aluno_id)
      var a = {
        id_finan_contas_receber: item.id,
             valor_total: item.valor,
             nome_produto: this.lista_finan_contas_recebre.pedido.nome_produto,
             usuario_id : aluno_id

             }
      await this.$store.dispatch("financeiro/pagar", a);
      console.log(this.link_boleto);
         }
      if (item.status == 3) {
        //  this.alertMessage(this.mensagem_alert.tipo,this.mensagem_alert.message);
        window.open(item.link_boleto, "_blank");
      } else if (this.mensagem_alert.tipo == "error") {
        this.alertMessage(
          this.mensagem_alert.tipo,
          this.mensagem_alert.message
        );
      } 
      if (item.status == 1) {
        this.alertMessage("success", "gerado com sucesso");
        window.open(this.link_boleto, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>